import React from 'react';
import Rank from './Rank.es6.jsx';

const Board = ({ leaderboard, defaultAvatar }) => (
  leaderboard.length > 0 ?
    <ol className="leaderboard">
      {leaderboard.map(rank => <Rank rank={rank} key={rank.rank} defaultAvatar={defaultAvatar} />)}
    </ol> :
    <p className="body-regular-light">Leaderboard not available.</p>
);

export default Board;
