import React from 'react';
import CourseSmallBanner from './CourseSmallBanner.es6.jsx';

class CourseList extends React.Component {
  constructor(props) {
    super(props);

    this.defaultPagination = 24;
    this.state = {
      itemsToShow: this.defaultPagination,
      itemsShowing: this.defaultPagination,
      hideShowMore: false,
    };
  }

  showMoreHandler = (e) => {
    e.preventDefault();

    this.props.clickHandler();

    this.setState({
      itemsShowing: this.state.itemsShowing + this.state.itemsToShow,
    }, this.hideShowMoreBtn);
  }

  hideShowMoreBtn() {
    if (this.state.itemsShowing >= this.props.courses.length) {
      this.setState({ hideShowMore: true });
    }
  }

  render() {
    const { courses, next } = this.props;

    return (
      <div>
        {Optional(courses.length).map(() =>
          <div key="course-list-container">
            <header className="ho-section-courses__header">
              <h3>All Courses</h3>
            </header>
            <div className="course-list">
              {
                courses
                  .slice(0, this.state.itemsShowing)
                  .map((course, index) => <CourseSmallBanner key={index} {...course} />)
              }
            </div>
            <footer className="course-list__footer">
              {Optional(next).map(() =>
                <a key="show-more" href="#" onClick={this.showMoreHandler}>
                  Show more
                  <i className="showmore-arrow"></i>
                </a>
              )}
            </footer>
          </div>
        )}
      </div>
    );
  }
}

export default CourseList;
