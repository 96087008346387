import Loader from '../Loader.es6.jsx';
import React from 'react';

class PlacesSearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showNoResults: false,
      places: [],
      placesQuery: {
        types: ['(regions)'],
      },
    };

    this.queueTimeout = 500;
  }

  componentDidMount() {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    this.setFirstLocation();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query.input && nextProps.query.input !== this.props.query.input) {
      this.setState({
        loading: true,
        places: [],
      }, () => this.searchForPlaces(nextProps.query));
    }
  }

  setFirstLocation = () => {
    if (this.props.firstLocation) {
      this.searchForPlaces(this.props.query);
    }
  }

  searchForPlaces(query) {
    clearTimeout(this.state.queue);
    this.state.placesQuery.input = query.input;

    this.setState({
      showNoResults: false,
      isFocused: true,
      loading: true,
    }, () => {
      this.setState({ queue: setTimeout(() => {
        this.autoCompleteService.getPlacePredictions(this.state.placesQuery, this.setPlaces);
      }, this.queueTimeout) });
    });
  }

  setPlaces = (results, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK && this.state.isFocused) {
      this.setState({ places: results, loading: false });
    } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
      this.setState({ showNoResults: true, loading: false });
    }
  }

  handleClick = (e) => {
    // Push GA Event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'search result clicked',
      eventAction: 'Location Search Result Clicked',
      eventLabel: e.target.innerText,
    });
    e.preventDefault();
    this.sendPlaceDetails(e.target.dataset.place);
  }

  sendPlaceDetails = (externalRef) => {
    const request = { placeId: externalRef };
    this.placesService.getDetails(request, this.generatePlaceDetails);
  }

  generatePlaceDetails = (place, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const { location, viewport } = place.geometry;
      const lat = location.lat();
      const lng = location.lng();
      const externalRef = place.place_id;
      const northEast = {
        lat: viewport.getNorthEast().lat(),
        lng: viewport.getNorthEast().lng(),
      };
      const radius = this.calculateRadius(lat, lng, northEast);
      const { formatted_address: address } = place;

      this.setState({
        places: [],
      }, () => this.props.clickHandler({ lat, lng, externalRef, radius, address }));
    }
  }

  calculateRadius(lat, lng, northEast) {
    const earthRadius = 6378.8;
    const radiansDefault = 57.2958;

    // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
    const centerLat = lat / radiansDefault;
    const centerLng = lng / radiansDefault;
    const northLat = northEast.lat / radiansDefault;
    const northLng = northEast.lng / radiansDefault;

    const radius = earthRadius * Math.acos(
      Math.sin(centerLat) * Math.sin(northLat) +
      Math.cos(centerLat) * Math.cos(northLat) * Math.cos(northLng - centerLng)
    );

    return radius + (radius * 0.05);
  }

  buildDropdownContent = () => {
    if (this.state.showNoResults) {
      return (<p className="no-results">No results found</p>);
    } else if (this.state.loading) {
      return (<Loader />);
    }

    return this.state.places.map((place) =>
      <a
        key={place.place_id}
        href="#"
        data-place={place.place_id}
        onClick={this.handleClick}
      >
        {place.description}
      </a>
    );
  }

  render() {
    return (
      <div className="ho-dropdown">
        {this.buildDropdownContent()}
      </div>
    );
  }
}

export default PlacesSearchBox;
