import React from 'react';

const SubscriptionSave = ({
  discardHandler,
  saveHandler,
  showButtonLoader,
}) => (
  <div className="save-changes-bar">
    <div className="container-6">
      <a href="#" onClick={discardHandler} className="cta-secondary button-1 text-grey-3">
        Discard
      </a>
      {showButtonLoader ? (
        <button type="submit" name="button-loading" className="cta-main button-regular loading">Save Changes</button>
      ) : (
        <button
          className="cta-main button-regular"
          onClick={saveHandler}
        >
          Save Changes
        </button>
      )}
    </div>
  </div>
);

export default SubscriptionSave;
