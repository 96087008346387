import React from 'react';
class CountriesList extends React.Component {
  constructor(props) {
    super(props);
    this.firstLetters = {};
    this.createOrPush = (list, k, v) => {
      if (list[k] === undefined) {
        return list[k] = [v]; // eslint-disable-line no-return-assign
      }
      list[k].push(v);
    };
    props.countries.map(country => this.createOrPush(this.firstLetters, country.name[0], country));
  }

  render() {
    return (
      <section className="ho-section-courses">
        <h1>Select your country</h1>
        <div id="countries-list" className="course-list"> {
          Object.keys(this.firstLetters).map(key =>
            <nav key={key}>
              <h2 className="title-3">{key}</h2>
              <ul>{
                this.firstLetters[key].map(item =>
                  <li className="countryItem" key={item.slug}>
                    <img src={item.flag_url} width="26px" alt={`${item.name} flag`} />
                    <a href={item.has_regions ? `/countries/${item.slug}` : `/golf-courses/${item.slug}`} title={`Golf courses in ${item.name}`}>
                      {item.name} ({item.courses_count})
                    </a>
                  </li>
                )
              }</ul>
            </nav>
          )
        }</div>
      </section>
    );
  }
}

export default CountriesList;
