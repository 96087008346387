import React from 'react';
import ImageHelpers from '../../../helpers/images';
import CourseHelpers from '../../../helpers/course';

const CourseSmallBanner = (course) => (
  <a href={course.profile_url} className="course-item" title={course.name}>
    <div className="course-item__image">
      <img src={ImageHelpers.getImagePath(course.images, 'thumb.small')} alt={course.name} title={course.name} />
      <div className={`course-item__rating ${CourseHelpers.ratingClass(course.avg_rating)}`}>
        <i className="star"></i>
        <span>
          {course.avg_rating ? course.avg_rating.toFixed(1) : '-'}
        </span>
      </div>
    </div>
    <div className="course-item__content">
      <h4>{course.name}</h4>
      <p>{course.location}</p>
    </div>
  </a>
);

export default CourseSmallBanner;
