import React from 'react';
import Loader from '../Loader.es6.jsx';

class CoursesIdentitiesDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showNoResults: false,
      courses: [],
    };
    this.queueTimeout = 300;
  }

  componentDidMount() {
    this.handleSearch(this.props.query);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query && nextProps.query !== this.props.query) {
      this.handleSearch(nextProps.query);
    }
  }

  handleSearch(query) {
    clearTimeout(this.state.queue);

    this.setState({
      loading: true,
      queue: setTimeout(() => this.fetchCourses(query), this.queueTimeout),
    });
  }

  fetchCourses(query) {
    Get(`${this.props.api}/api/web/course_identities/search?q=${query}&count=15`, {
      headers: { Authorization: `Bearer ${this.props.token}` },
    })
      .then(this.setCoursesInfo);
  }

  setCoursesInfo = (data) => {
    if (data.course_identities.length !== 0) {
      this.setState({
        courses: data.course_identities,
        loading: false,
        showNoResults: false,
      });
    } else {
      this.setState({ showNoResults: true });
    }
  }

  clickHandler = (e) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'search result clicked',
      eventAction: 'Course Search Result Clicked',
      eventLabel: e.target.innerText.replace(/(?:\r\n|\r|\n)/g, ''),
    });
  }

  buildDropdownContent = () => {
    if (this.state.showNoResults) {
      return (<p className="no-results">No results found</p>);
    } else if (this.state.loading) {
      return Loader();
    }

    return this.state.courses.map((course) => {
      const innerHtml = { __html: `${course.name.replace(new RegExp(`(${this.props.query.replace(/\W+/g).split(' ').join('|')})`, 'gi'), '<strong>$1</strong>')}, <span>${course.location}</span>` };
      const url = () => course.profile_url;

      return (
        <li key={course.profile_url}>
          <a
            dangerouslySetInnerHTML={innerHtml}
            href={url()}
            onClick={this.clickHandler}
          ></a>
        </li>
      );
    });
  }

  render() {
    return (
      <div className="ho-dropdown">
        <ul className="ho-search-result-item ho-search-result">
          {this.buildDropdownContent()}
        </ul>
      </div>
    );
  }
}

export default CoursesIdentitiesDropdown;
