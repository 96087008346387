import React from 'react';
import CuratedSlider from './Slider.es6.jsx';

const CuratedList = ({ id, placeSlug, course_identities: data, title, logo, slug }) => {
  return (
    <section className="curated-list">
      <header className="ho-section-courses__header">
        <h3>
          {title}
          {Optional(logo).map(() =>
            <img key={title} src={logo} alt={title} />
          )}
        </h3>
        <a href={`/golf-courses/${placeSlug}/${slug}`}>View All <i className="arrow-slider-right"></i></a>
      </header>
      <CuratedSlider courses={data} />
    </section>
  );
};

export default CuratedList;
