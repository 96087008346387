import React from 'react';
class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: props.value,
      options: props.options,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ currentValue: nextProps.value });
  }

  handleChange = (e) => {
    this.setState({ currentValue: e.target.value }, () => {
      const value = (this.state.currentValue === 'false') ? false : this.state.currentValue;
      this.props.clickHandler(value);
    });
  }

  render() {
    const { currentValue, options } = this.state;

    return (
      <div className="input-select in-row">
        <select value={currentValue} onChange={this.handleChange} >
          {
            options.map((option, index) =>
              <option key={index} value={option.key}>{option.name}</option>
            )
          }
        </select>
      </div>
    );
  }
}

export default Dropdown;
