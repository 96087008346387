import React from 'react';

const PageTitleHeader = (props) => (
  <header className="user-page-header flex-center-h">
    <h4 className="section-title title-2">{props.title}</h4>

    {props.description
      ? <p className="section-description regular body-medium">{props.description}</p>
      : ''
    }

    {props.descriptionLarge
      ? <p className="section-description large headline-medium">{props.descriptionLarge}</p>
      : ''
    }

    {props.secondaryLinkLabel
      ? <a href={props.secondaryLinkURL} className="secondary-link body-bold">{props.secondaryLinkLabel}</a>
      : ''
    }
  </header>
);

export default PageTitleHeader;
