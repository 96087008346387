export default {
  ratingClass: (avgRating) => {
    if (avgRating >= 0.1 && avgRating <= 1.9) {
      return 'rt-1';
    } else if (avgRating >= 2 && avgRating <= 2.9) {
      return 'rt-2';
    } else if (avgRating >= 3 && avgRating <= 3.9) {
      return 'rt-3';
    } else if (avgRating >= 4 && avgRating <= 4.9) {
      return 'rt-4';
    } else if (avgRating === 5) {
      return 'rt-5';
    }

    return 'rt-0';
  },
};
