import React from 'react';
import Board from './Board.es6.jsx';

export default class LeaderboardExpanded extends React.Component {
  constructor(props) {
    super(props);
    this.closeExpandedView = this.closeExpandedView.bind(this);
    this.state = { expanded: true };
  }

  closeExpandedView() {
    this.props.unmountMe();
  }

  render() {
    return (
      <div className="course-section-expanded leaderboard-expanded">
        <div className="course-section-expanded-header">
          <div className="ho-container">
            <div className="header-info">
              <h4 className="title-5">Leaderboard</h4>
              <h3 className="title-4">{this.props.course.name}</h3>
            </div>

            <div className="header-actions">
              <span className="ic-close-gray" onClick={this.closeExpandedView}></span>
            </div>
          </div>
        </div>

        <div className="ho-container-small">
          <Board leaderboard={this.props.leaderboard} defaultAvatar={this.props.defaultAvatar} />
        </div>
      </div>
    );
  }
}
