import React from 'react';
import Lightbox from 'react-images';
import Loader from '../../Loader.es6.jsx';

export default class CommunityPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { photos: this.props.communityPhotos, index: 0, isOpen: false };
    this.openGallery = this.openGallery.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.communityPhotos !== this.props.communityPhotos) {
      this.updateCommunityPhotos();
    }
  }

  updateCommunityPhotos() {
    this.onClose();
    this.setState({ photos: this.props.communityPhotos });
  }

  onClose = () => (
    this.setState({ isOpen: false })
  );

  moveNext = () => (
    this.setState({ index: (this.state.index + 1) % this.state.photos.length })
  );

  movePrev = () => (
    this.setState({ index: (this.state.index + this.state.photos.length - 1) % this.state.photos.length })
  );

  openGallery = index => (
    this.setState({ index, isOpen: true })
  );

  lightboxStyles = {
    footer: {
      font: '16px aileronregular, arial, tahoma, sans-serif',
    },
  };

  render() {
    const photoObject = (photoInfo) => ({
      src: photoInfo.images.small,
      srcSet: [
        `${photoInfo.images.small} 800w`,
        `${photoInfo.images.medium} 1280w`,
        `${photoInfo.images.big} 1920w`,
      ],
      caption: `Taken by ${photoInfo.user.full_name} on ${new Date(photoInfo.created_at).toLocaleDateString()}`,
      authName: photoInfo.user.full_name,
      date: new Date(photoInfo.created_at).toLocaleDateString(),
    });

    const Photo = ({ photo, onClick, index }) => (
      <li>
        <a title={`${photoObject(photo).caption}`} onClick={() => onClick(index)} style={{ backgroundImage: `url(${photo.images.thumb.small})` }}>
          <div className="overlay-info">
            <p>{photoObject(photo).authName}</p>
            <p>{photoObject(photo).date}</p>
          </div>
        </a>
      </li>
    );

    return (
      <ul className="photos">
        <div className="photos-container">
          {Optional(this.state.photos === undefined).map(() => <Loader key="cps-loader" />)}

          {Optional(this.state.photos && this.state.photos.length === 0).map(() =>
            <p key="pts-not-available" className="body-regular-secondary content-not-available">Photos not available for this course.</p>)}

          {Optional(this.state.photos).map((photos) =>
            photos.map((photo, index) =>
              <Photo onClick={this.openGallery} index={index} photo={photo} key={index} />))}

          <Lightbox
            images={(this.state.photos || []).map(photo => photoObject(photo))}
            onClickImage={this.moveNext}
            currentImage={this.state.index}
            isOpen={this.state.isOpen}
            onClose={this.onClose}
            onClickPrev={this.movePrev}
            onClickNext={this.moveNext}
            theme={this.lightboxStyles}
          />
        </div>
      </ul>
    );
  }
}
