import React from 'react';
import ImageHelpers from '../../helpers/images';
import CourseHelpers from '../../helpers/course';

const CuratedSliderItem = (item) => {
  const {
    name,
    location,
    images,
    profile_url: profileUrl,
    avg_rating: avgRating,
  } = item;

  return (
    <a href={profileUrl} title={name}>
      <figure className="curated-item">
        <div className="curated-item__image">
          <img
            src={ImageHelpers.getImagePath(images, 'thumb.medium')}
            data-lazy={ImageHelpers.getImagePath(images, 'thumb.medium')}
            alt={name}
            title={name}
          />
        </div>
        <figcaption>
          <h4>{name}</h4>
          <p className="subhead">{location}</p>
          <div className={`course-item__rating ${CourseHelpers.ratingClass(avgRating)}`}>
            <i className="star"></i>
            <span>
              {avgRating ? avgRating.toFixed(1) : '-'}
            </span>
          </div>
        </figcaption>
      </figure>
    </a>
  );
};

export default CuratedSliderItem;
