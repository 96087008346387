function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16) * 1.0 / 255,
    g: parseInt(result[2], 16) * 1.0 / 255,
    b: parseInt(result[3], 16) * 1.0 / 255,
  } : null;
}


function luminance(rgb) {
  const colors = [rgb.r, rgb.g, rgb.b];
  const vs = colors.map(c => c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4));
  return vs[0] * 0.2126 + vs[1] * 0.7152 + vs[2] * 0.0722;
}

// Returns the text color appropriate to be used on a given background color (hex-presented)
// Implements the same algorithm used by the applications
function textColor(hex) {
  const rgb = hexToRgb(hex);
  const lum = luminance(rgb);
  return lum > 0.5 ? '#000000' : '#FFFFFF';
}

export default textColor;
