import React from 'react';
import Forecast from './Forecast.es6.jsx';
import Scorecard from './Scorecard.es6.jsx';
import Leaderboard from '../leaderboards/Index.es6.jsx';
import Reviews from '../reviews/Index.es6.jsx';
import CommunityPhotos from './CommunityPhotos.es6.jsx';
import StoreButtons from '../../StoreButtons.es6.jsx';
import Select from 'react-select';

import campaignParams from '../../../helpers/mixpanel/index.es6.jsx';
import urlHelpers from '../../../helpers/url';
import ImageHelpers from '../../../helpers/images';
import CourseHelpers from '../../../helpers/course';
import CourseProfileHelpers from '../../../helpers/courseProfile';

export default class CourseIdentityProfile extends React.Component {
  constructor(props) {
    super(props);

    this.courseIdentityInfo = props.course_identity;
    this.region_slug = props.course_identity.region_slug;
    this.course_identity_slug = props.course_identity.slug;

    this.courseIdentityInfo.phone_number_ommited = props.course_identity.phone_number
      ? `${this.courseIdentityInfo.phone_number.substr(0, 8)} *** ***`
      : null;

    this.showFullPhoneNumber = this.showFullPhoneNumber.bind(this);
    this.pop = {
      token: props.token,
      api: props.pop_api_url,
    };
    this.rex = {
      token: props.rex_api_token,
      api: props.rex_api_url,
    };

    this.courses = props.course_identity.courses;

    this.state = {
      currentCoursePublicId: this.courses[0].public_id,
    };
  }

  componentDidMount() {
    campaignParams(urlHelpers.urlParameters());

    this.getForecast();
    this.getCommunityPhotos();
  }

  getCommunityPhotos = () =>
    CourseProfileHelpers.fetchCommunityPhotos(this.pop, this.props.course_identity.region_slug, this.props.course_identity.slug)
      .then(json => this.setState({ community_photos: json.data }))

  getForecast = () =>
    CourseProfileHelpers.fetchForecast(this.rex, this.props.course_identity.latitude, this.props.course_identity.longitude)
      .then((json) => this.setState({ forecast: json.data }))

  getBanner() {
    if (this.props.course_identity.images.length > 0) {
      return ImageHelpers.getImagePath(this.props.course_identity.images, 'custom')
        .replace(':recipe', 'cover')
        .replace(':format', '1280x450');
    }
    return this.props.default_profile_image;
  }

  showFullPhoneNumber(e) {
    e.preventDefault();
    this.setState({ showFullPhoneNumber: true, telephone: this.props.course_identity.phone_number });
  }

  setCurrentCoursePublicId = (publicId) => {
    this.setState({ currentCoursePublicId: publicId.value });
  }

  render() {
    const courseIdentity = this.props.course_identity;
    const currentCourse = { publicId: this.state.currentCoursePublicId };
    const hasMultipleCourses = this.courses && this.courses.length > 1;
    let multipleCoursesList;

    if (hasMultipleCourses) {
      multipleCoursesList = this.courses.map(course => (
        {
          value: course.public_id,
          label: <span dangerouslySetInnerHTML={{ __html: `<span class="course-name">${course.name}</span>\n<span class="course-details">${course.holes_count} Holes</span>` }}></span>,
        }
      ));
    }

    const mapSrc = () => {
      const addressParams = courseIdentity.address ? `place?q=${encodeURIComponent(courseIdentity.address)}` : `view?center=${courseIdentity.latitude},${courseIdentity.longitude}&zoom=16`;
      return `https://www.google.com/maps/embed/v1/${addressParams}&key=${this.props.google_maps_api_key}`;
    };

    const directionsParams = () => courseIdentity.address ? `place?q=${encodeURIComponent(courseIdentity.address)}` : `view?ll=${courseIdentity.latitude},${courseIdentity.longitude}&z=16`;

    return (
      <div>
        <section className="hero-course-profile" style={{ backgroundImage: `url(${this.getBanner()})` }}></section>

        <div className="ho-container course-profile-container">
          <section className="course-content">
            <div className="course-content-about">
              <Forecast forecast={this.state.forecast} unit={courseIdentity.temperature_unit} />

              <ol className="breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList">
                <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                  <a itemProp="item" href="/golf-courses" className="body-bold">
                    <span itemProp="name">Golf Courses</span></a>
                  <meta itemProp="position" content="1" />
                </li>
                <li><i className="ic-chevron-right"></i></li>
                <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                  <a id="course-location" itemProp="item" href={`/golf-courses/${courseIdentity.region_slug}`} className="body-bold">
                    <span itemProp="name">{courseIdentity.location}</span></a>
                  <meta itemProp="position" content="2" />
                </li>
              </ol>

              <div className="course-name">
                <h1 itemProp="name">{courseIdentity.name}</h1>
                <div className={`course-rating caption-1-bold ${CourseHelpers.ratingClass(courseIdentity.avg_rating)}`}>
                  <span>
                    {courseIdentity.avg_rating ? courseIdentity.avg_rating.toFixed(1) : '-'}
                  </span>
                </div>
              </div>
              <div className="course-subtitle">
                <h2>{this.props.subtitle}</h2>
              </div>

              <div itemProp="description" className="course-description">
                <h3 className="caption-1-custom text-light">Information</h3>
                {courseIdentity.description
                  ? <p>{courseIdentity.description}</p>
                  : <p className="body-regular-secondary">Description not available for this course.</p>
                }

                <ol className="ho-hide-desk course-profile-mobile-info">
                  {this.courseIdentityInfo.phone_number && !this.state.showFullPhoneNumber ?
                    <li>
                      <a href="#" onClick={this.showFullPhoneNumber}>
                        <p className="body-bold">Call course</p>
                        <div className="caption-1-custom text-light">{this.courseIdentityInfo.phone_number_ommited} <div className="show-phone">show more</div></div>
                      </a>
                    </li> : ''
                  }

                  {this.courseIdentityInfo.phone_number && this.state.showFullPhoneNumber ?
                    <li>
                      <a href={`tel:${this.courseIdentityInfo.phone_number}`}>
                        <p className="body-bold">Call course</p>
                        <p className="subhead text-lighter">{this.courseIdentityInfo.phone_number}</p>
                      </a>
                    </li> : ''
                  }

                  {Optional(courseIdentity.email).map((email) =>
                    <li key={email}>
                      <a href={`mailto:${email}`}>
                        <p className="body-bold">Email</p>
                        <p className="subhead text-lighter">{email}</p>
                      </a>
                    </li>
                  )}

                  {Optional(courseIdentity.url).map((url) =>
                    <li key={url}>
                      <a href={url} target="_blank">
                        <p className="body-bold">Website</p>
                        <p className="subhead text-lighter">{url}</p>
                      </a>
                    </li>
                  )}

                  {Optional(courseIdentity.address).map((address) =>
                    <li key={address}>
                      <a href={`//google.com/maps/search/${directionsParams()}`} target="_blank">
                        <p className="body-bold">Directions</p>
                        <p className="subhead text-lighter">{address}</p>
                      </a>
                    </li>
                  )}
                </ol>
              </div>
            </div>
            {Optional(hasMultipleCourses).map((_, index) =>
              <div key={index} className="course-content-select-course">
                <div className="input-group input-select">
                  <label>Select Course</label>

                  <Select
                    value={this.state.currentCoursePublicId}
                    options={multipleCoursesList}
                    clearable={false}
                    searchable={false}
                    onChange={this.setCurrentCoursePublicId}
                  />
                </div>
              </div>
            )}
            <div>
              <Scorecard course={currentCourse} service={this.pop} />
              <Leaderboard course={currentCourse} service={this.pop} defaultAvatar={this.props.default_avatar} />
            </div>

            <section className="ho-hide-desk course-side-photos">
              <div className="section-title">
                <h3 className="caption-1-custom text-light">Community Photos</h3>
              </div>
              <CommunityPhotos communityPhotos={this.state.community_photos} />
            </section>

            <Reviews course={courseIdentity} service={this.pop} defaultAvatar={this.props.default_avatar} />

            <div className="download-app">
              <h3 className="package-title title-4">Download the App</h3>
              <StoreButtons />
            </div>
          </section>
          <aside>
            <section className="course-side-info">
              <ol className="ho-list-info">

                {this.courseIdentityInfo.phone_number && !this.state.showFullPhoneNumber ?
                  <li itemProp="telephone">
                    <p className="caption-1-custom text-light">Phone number</p>
                    <div className="phone-number"><p className="headline">{this.courseIdentityInfo.phone_number_ommited}</p>
                      <a href="#" className="show-phone" onClick={this.showFullPhoneNumber}>
                        <p className="caption-1 text-bold">Show more</p>
                      </a>
                    </div>
                  </li> : ''
                }

                {this.courseIdentityInfo.phone_number && this.state.showFullPhoneNumber ?
                  <li itemProp="telephone">
                    <p className="caption-1-custom text-light">Phone number</p>
                    <p className="headline">{this.courseIdentityInfo.phone_number}</p>
                  </li> : ''
                }

                {Optional(courseIdentity.email).map((email) =>
                  <li key={email} itemProp="email">
                    <p className="caption-1-custom text-light">Email</p>
                    <a href={`mailto:${email}?subject=Through Hole19`}><p className="headline">{email}</p></a>
                  </li>
                )}

                {Optional(courseIdentity.url).map((url) =>
                  <li key={url} itemProp="url" className="ho-ico-info-site">
                    <p className="caption-1-custom text-light">Website</p>
                    <a href={url} target="_blank">{url}</a>
                  </li>
                )}

                {Optional(courseIdentity.address).map((address) =>
                  <li key={address} itemProp="address">
                    <p className="caption-1-custom text-light">Address</p>
                    <p className="headline">{address}</p> <br />

                    <section className="course-side-map">
                      <iframe className="ho-map ho-hide-mobile" src={mapSrc()} width="100%" height="180" frameBorder="0" style={{ border: '0' }} allowFullScreen async defer></iframe>
                    </section>
                  </li>
                )}
              </ol>
            </section>

            <section className="course-side-photos">
              <div className="section-title">
                <h3 className="caption-1-custom text-light">Community Photos</h3>
              </div>
              <CommunityPhotos communityPhotos={this.state.community_photos} />
            </section>
          </aside>
        </div>
      </div>
    );
  }
}
