import urlHelpers from '../../helpers/url';

const methods = {
  secureHost: () => {
    let httpsHost = '';
    try {
      if (!window.location.hostname.match(/www-local/)) {
        httpsHost = `https://${window.location.hostname}`;
      }
    } catch (e) {
      // .. do nothing
    }

    return httpsHost;
  },

  updateURLParameter: (url, params) => {
    let newAdditionalURL = '';
    let temp = '';
    let tempArray = url.split('?');
    const baseURL = tempArray[0];
    const additionalURL = tempArray[1];

    if (additionalURL) {
      tempArray = additionalURL.split('&');
      for (let i = 0; i < tempArray.length; i++) {
        const currentParam = tempArray[i].split('=')[0];
        if (!params[currentParam]) {
          newAdditionalURL += temp + tempArray[i];
          temp = '&';
        }
      }
    }

    const paramsArray = [];
    const objectKeys = Object.keys(params);
    const objectValues = Object.values(params);

    for (let i = 0; i < objectKeys.length; i++) {
      const k = objectKeys[i];
      const v = objectValues[i];

      if (params.hasOwnProperty(k) && v) {
        paramsArray.push(`${k}=${v}`);
      }
    }

    return `${baseURL}?${newAdditionalURL}${temp}${paramsArray.join('&')}`;
  },

  urlParameters: (parameters) => {
    const str = (parameters || window.location.search).substring(1);

    if (!str) {
      return {};
    }

    return str.split('&').reduce((prev, curr) => {
      const data = prev;
      const p = curr.split('=');
      data[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return data;
    }, {});
  },

  updateAddressBar: (params, excludeParams) => {
    const url = `${window.location.origin}${window.location.pathname}`;
    const currentParams = urlHelpers.urlParameters();

    if (excludeParams) {
      excludeParams.forEach(k => delete currentParams[k]);
    }

    const searchBarUrl = urlHelpers.updateURLParameter(url, Object.assign({}, currentParams, params));
    window.history.pushState({}, document.title, searchBarUrl);
  },

  buildQueryStringFromObject: (obj) => {
    const str = [];
    Object.keys(obj).forEach((key) => {
      if (obj.hasOwnProperty(key) && obj[key]) {
        str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
      }
    });

    return str.join('&');
  },
};

if (typeof window === 'object') {
  window.urlHelpers = methods;
}
export default methods;
