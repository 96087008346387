import React from 'react';
import moment from 'moment';

class ShowSubscription extends React.Component {
  constructor(props) {
    super(props);

    this.authenticityToken = props.csrf_token;
  }

  formatSubscriptionDate(date) {
    return moment(date).format('ll');
  }

  formatSubscriptionStore() {
    let storeName;

    switch (this.props.subscription_store) {
      case 'apple':
        storeName = 'Apple';
        break;
      case 'google':
        storeName = 'Google Play';
        break;
      case 'stripe':
        storeName = 'Web';
        break;
      default:
        storeName = 'N/A';
    }

    return storeName;
  }

  render() {
    const subscriptionWillBeCanceled = this.props.subscription_will_be_canceled;
    const canManageSubscription = this.props.subscription_can_be_managed;

    return (
      <div className="premium-sub">
        <h5 className="title-5">Subscriptions</h5>
        <br />
        <div className="premium-sub__info">
          <h6>Hole19 Premium</h6>
          {Optional(this.props.subscription_plan).map(() =>
            <p key="subscriptionPlan">{this.props.subscription_plan} Subscription Plan</p>)}
          {Optional(this.props.subscription_store).map(() =>
            <p key="subscriptionStore">Subscribed via {this.formatSubscriptionStore()}</p>)}
          <p key="subscriptionEnd">{subscriptionWillBeCanceled ? ('Cancels') : ('Renews')} on {this.formatSubscriptionDate(this.props.subscription_end)}</p>
          {Optional(this.props.subscription_start).map(() =>
            <p key="subscriptionStart">Subscriber since {this.formatSubscriptionDate(this.props.subscription_start)}</p>)}

          {Optional(canManageSubscription).map(() =>
            <div>
              <form action="/premium/stripe-portal" method="post">
                <input type="hidden" name="authenticity_token" value={this.authenticityToken} readOnly />
                <input className="button-regular" style={{ marginTop: 20 }} type="submit" value="Manage subscription" />
              </form>
            </div>
          )}

          {Optional(!canManageSubscription).map(() =>
            <p className="notice">Subscriptions purchased via App Store (Apple) or Play Store (Google) must be managed through their own platforms. You can access these platforms from inside the app by tapping 'More' at the bottom bar and then 'Manage' at the top of the screen.</p>)}
        </div>
      </div>
    );
  }
}

export default ShowSubscription;
