export default {
  updateSubscription: (service, params) =>
    Put(`${service.api}/${service.url}`, {
      body: JSON.stringify(params),
    }),
  getSubscriptions: (service, params) => {
    let url = `${service.api}/${service.url}`;

    if (params) {
      url += `?${new URLSearchParams(params).toString()}`;
    }

    return Get(url);
  },
  setParams({ email, token, subscriptions } = {}) {
    const params = { email };

    if (subscriptions) { // subscriptions is not required in getSubscriptions
      params.subscriptions = {};
      for (const sub of subscriptions) {
        params.subscriptions[sub.key] = sub.value;
      }
    }

    if (token) {
      params.token = token;
    }

    return params;
  },
};

