
const Req = (url, options = {}) => { // eslint-disable-line no-unused-vars
  let defaultOptions = options;

  if (!options.excludeCredentials && !/rex(-stg)?\.hole19golf\.com|amazonaws/.test(url)) {
    defaultOptions = Object.assign(options, { credentials: 'include' });
  }

  return fetch(url, Object.assign(options, defaultOptions))
    .then((response) => {
      if (response.status >= 500 && options.retryTimes > 0) {
        options.retryTimes = options.retryTimes - 1;
        console.log(`status: ${response.status}; retrying call. ${options.retryTimes} times remaining.`);
        return Req(url, options);
      } else if (response.status >= 200 && response.status <= 204 || response.status === 422) {
        return response.json();
      }
      throw response;
    }, (error) => {
      console.error(`[H19Api] Request: ${url}`);
      console.error(`[H19Api] Error: ${error}`);
    });
};

function setHeaders(options) {
  const headers = options.headers || {};
  headers['Content-Type'] = 'application/json';
  return headers;
}

const genericCaller = (method) => (url, options) => {
  const headers = setHeaders(options);
  return Req(url, { method, headers, ...options });
};

/**
 * Executes an HTTP GET request.
 */
export const Get = (url, options) => Req(url, options); // eslint-disable-line no-unused-vars

/**
 * Executes an HTTP POST request
 */
export const Post = genericCaller('POST'); // eslint-disable-line no-unused-vars

/**
 * Executes an HTTP PUT request
 */
export const Put = genericCaller('PUT'); // eslint-disable-line no-unused-vars
