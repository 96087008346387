import { Get } from '../networking.es6.js';

export default {
  fetchCommunityPhotos(service, regionSlug, courseIdentitySlug) {
    return Get(`${service.api}/api/web/regions/${regionSlug}/course_identities/${courseIdentitySlug}/community_photos`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchReviews(service, regionSlug, courseIdentitySlug) {
    return Get(`${service.api}/api/web/regions/${regionSlug}/course_identities/${courseIdentitySlug}/reviews`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchForecast(service, latitude, longitude) {
    return Get(`${service.api}/api/v2/forecast?latitude=${latitude}&longitude=${longitude}`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchScorecard(service, coursePublicId) {
    return Get(`${service.api}/api/web/courses/${coursePublicId}/scorecard`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
  fetchLeaderboard(service, coursePublicId) {
    return Get(`${service.api}/api/web/courses/${coursePublicId}/leaderboard`, {
      headers: { Authorization: `Bearer ${service.token}` },
    });
  },
};
