import { Get } from '../../../../helpers/networking.es6.js';

const CancelableReq = (req) => {
  let canceled = false;

  const cancelable = req.then((response) => {
    if (!canceled) {
      return Promise.resolve(response);
    }
  });

  return {
    request: cancelable,
    cancel: () => { canceled = true; },
  };
};

const PopApi = (api) => {
  function get(path) {
    return CancelableReq(Get(`${api.base_url}${path}`, {
      headers: { Authorization: `Bearer ${api.token}` },
    }));
  }

  return {
    getCourseIdentity: (regionSlug, courseIdentitySlug) => get(`/api/web/regions/${regionSlug}/course_identities/${courseIdentitySlug}`),
    searchCourseIdentities: (query) => get(`/api/web/course_identities/search?q=${query}&count=15`),
  };
};

export default PopApi;
