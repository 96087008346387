/* eslint-disable react/no-multi-comp */

import React from 'react';
import GenericModal from './GenericModal.es6.jsx';

class InviteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false,
    };
  }

  copyToClipboard = (e, text) => {
    e.preventDefault();

    // copy
    const tempInput = document.createElement('textarea');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    // acknowledge copy success
    this.setState({ copySuccess: true });
    setTimeout(() => {
      this.setState({ copySuccess: false });
    }, 2000);
  };

  render() {
    const successClasses = ['copy-success', 'caption-1', (this.state.copySuccess ? 'visible' : null)].filter((obj) => obj).join(' ');

    return (
      <GenericModal
        visible={this.props.visible}
        onCloseClick={this.props.onCloseClick}
        modalTitle="Invite your friends"
        modalSubtitle="Send the invite link to your friends."
      >
        <div className="sharing-link">
          <p className="subhead">{this.props.url}</p>
        </div>

        <a href="#" className="button-1 text-blue" onClick={(e) => this.copyToClipboard(e, this.props.url)}>Copy Invite Link</a>

        <p className={successClasses}>Link copied!</p>
      </GenericModal>
    );
  }
}

class ShareModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false,
    };
  }

  copyToClipboard = (e, text) => {
    e.preventDefault();

    // copy
    const tempInput = document.createElement('textarea');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    // acknowledge copy success
    this.setState({ copySuccess: true });
    setTimeout(() => {
      this.setState({ copySuccess: false });
    }, 2000);
  };

  render() {
    const successClasses = ['copy-success', 'caption-1', (this.state.copySuccess ? 'visible' : null)].filter((obj) => obj).join(' ');

    return (
      <GenericModal
        visible={this.props.visible}
        onCloseClick={this.props.onCloseClick}
        modalTitle="Share with your friends"
        modalSubtitle="Copy the sharing link and let anyone see leaderboard."
      >
        <div className="sharing-link">
          <p className="subhead">{this.props.url}</p>
        </div>

        <a href="#" className="button-1 text-blue" onClick={(e) => this.copyToClipboard(e, this.props.url)}>Copy Share Link</a>

        <p className={successClasses}>Link copied!</p>
      </GenericModal>
    );
  }
}

const DeleteModal = ({ visible, onCloseClick, children }) => (
  <GenericModal
    visible={visible}
    onCloseClick={onCloseClick}
    modalTitle="Delete Leaderboard?"
    modalSubtitle="This will permanently delete this leaderboard. You cannot revert this action."
  >
    {children}
  </GenericModal>
);

const LeaveModal = ({ visible, onCloseClick, children }) => (
  <GenericModal
    visible={visible}
    onCloseClick={onCloseClick}
    modalTitle="Leave Leaderboard?"
    modalSubtitle="Are you sure your would like to leave this leaderboard? Any valid rounds will no longer appear."
  >
    {children}
  </GenericModal>
);

const PlayersListModal = ({ visible, onCloseClick, players }) => (
  <GenericModal
    defaultModalStyles={false}
    visible={visible}
    onCloseClick={onCloseClick}
    extraClass="modal-players-list"
    modalTitle={`${players.length} Players`}
  >
    <ul className="players-list">
    {players.map((player, index) => (
      <li className="cell" key={`${index}+${player.fullName}`}>
        <div className="avatar-container">
          {Optional(player.tee).map(() => (
            <p className="player-tee text-upper" style={{ backgroundColor: player.tee.backgroundColor, color: player.tee.textColor }}>{player.tee.name}</p>
          ))}

          <img src={player.avatarUrl} alt={player.fullName} className="player-avatar avatar-32 round" />
        </div>

        <p className="player-name body-bold">{player.fullName}</p>
        <p className="caption-2 text-light player-subtitle">{player.subtitle}</p>
      </li>
    ))}
    </ul>
  </GenericModal>
);

const TutorialModal = ({ visible, onCloseClick }) => (
  <GenericModal
    visible={visible}
    onCloseClick={onCloseClick}
    extraClass="modal-tutorial"
    modalTitle="Add my round to this leaderboard"
  >
    <ol className="steps" role="list">
      <li><p>Join this leaderboard by tapping the green <strong>“Join”</strong> button.</p></li>
      <li><p>Start a round using Hole19 on this course</p></li>
      <li><p>Make sure you select the correct <strong>tee</strong> and <strong>playing handicap</strong>.</p></li>
    </ol>

    <p className="note subhead text-light">Rounds started before the day of the leaderboard will not be shown here.</p>

    <a href="#" className="button-1 text-blue" onClick={onCloseClick}>Got It</a>
  </GenericModal>
);

const Modals = {
  InviteModal,
  ShareModal,
  DeleteModal,
  LeaveModal,
  PlayersListModal,
  TutorialModal,
};

export default Modals;
