import React from 'react';

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      hideCloseAction: this.props.hideCloseAction || false,
    };
  }

  handleCloseAction = () => {
    this.setState({ isVisible: false });

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return (
      this.state.isVisible &&
        <div className={`alert-bar ${this.props.classes || ''} ${this.props.withCloseAction ? 'with-close' : 'without-close'}`}>
          <p className="subhead-bold">
            {this.props.message}
            {this.props.link &&
              <a href={this.props.link.url}>{this.props.link.text}</a>
            }
          </p>

          {!this.state.hideCloseAction &&
            <button className="action-close" onClick={this.handleCloseAction}></button>
          }
        </div>
    );
  }
}
