import React from 'react';

class ConsentSubscriptionSave extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      consentCheck: false,
      hasErrors: false,
    };
  }

  validate(key) {
    return !!this.state[key];
  }

  formHandler = e => {
    e.preventDefault();

    const isValid = this.validate('consentCheck');

    if (isValid) {
      this.props.saveHandler(e);
    } else {
      this.props.showAlertHandler('terms');
    }
  };

  handleInput = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="preferences-consent-bar">
        <form
          onSubmit={this.formHandler}
          className="preferences-consent-form"
        >
          <div className="container-10 flex-repel">
            <div className="left">
              <div className="input-checkbox">
                <input
                  type="checkbox"
                  name="consentCheck"
                  id="check"
                  onChange={this.handleInput}
                />
                <label htmlFor="check" />
              </div>
              <label htmlFor="check" className="subhead-medium">I explicitly accept Hole19 <a href="/terms">Terms &amp; Conditions</a> and <a href="/terms/privacy-policy">Privacy Policy</a></label>
            </div>

            <div className="right">
              <input
                type="submit"
                className="button-small"
                value="I Agree and Save"
              />
            </div>
          </div>
        </form>

        <div className="overlay"></div>
      </div>
    );
  }
}

export default ConsentSubscriptionSave;
