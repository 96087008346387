import React from 'react';

const Regions = (props) => (
  <div>
    <section className="ho-hero-course ho-container">
      <div className="top-breadcrumb">
        <a href="/golf-courses">Golf Courses</a>
        <span>{props.regions.country.name}</span>
      </div>

      <img src={props.regions.country.flag_url} className="country-flag" />
      <h1 className="country-name">{props.regions.country.name}</h1>
      <h3 className="country-courses-count"><strong>{props.regions.regions.reduce((acc, region) => acc + region.courses_count, 0)}</strong> Golf Courses</h3>
      <h4>Select a region</h4>
    </section>

    <section id="regions-list" className="ho-section-courses">
      <ul className="ho-all-courses-list">{
        props.regions.regions.map((region) =>
          <ol key={region.slug}>
            <li className="regions-list">
              <img src={region.flag_url} alt={`${region.name} flag`} />
              <a href={`/golf-courses/${region.slug}`} title={`Golf courses in ${region.name}`}>
              {region.name} ({region.courses_count})
              </a>
            </li>
          </ol>
        )
      }</ul>
    </section>
  </div>
);

export default Regions;
