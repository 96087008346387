import React from 'react';
import onClickOutside from 'react-onclickoutside';
import Tabs from './Tabs.es6.jsx';
import Tab from './Tab.es6.jsx';
import PlacesSearchBox from './PlacesSearchBox.es6.jsx';
import CoursesIdentitiesDropdown from './CoursesDropdown.es6.jsx';

class FilterWhere extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: props.location || '',
      query: { input: props.location || '' },
      showDropdown: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location && nextProps.location !== this.props.location) {
      const query = { input: nextProps.location };
      this.setState({ query, inputValue: nextProps.location });
    }
  }

  handleClickOutside = () => {
    this.searchInput.blur();
    this.handleState('showDropdown')(false);
  }

  handleState = (key) => (
    (value) => {
      this.setState({ [key]: value });
    }
  );

  handleFetch = (data) => {
    this.setState({
      inputValue: data.address,
      showDropdown: false,
    }, () => this.props.fetchHandler(data));
  }

  handleClick = () => {
    if (this.state.inputValue.length) {
      this.handleState('showDropdown')(true);
    }
  }

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({ inputValue: value }, () => this.setQuery(value));
  }

  setQuery(query) {
    const dropdownState = this.buildDropdownState(query);

    this.setState({
      query: { input: dropdownState.inputValue },
    }, () => this.handleState('showDropdown')(dropdownState.showDropdown));
  }

  buildDropdownState(query) {
    const isValidQueryLength = query.trim().length >= 3;
    return {
      inputValue: isValidQueryLength ? query : '',
      showDropdown: isValidQueryLength,
    };
  }

  render() {
    return (
      <div className={`global-search__where ${this.props.haveBorder ? 'border-right' : ''}`}>
        <form className="filter filter--where" onSubmit={(e) => e.preventDefault()}>
          <div className="form-control">
            <label>Where</label>
            <input
              type="search"
              ref={(input) => { this.searchInput = input; }}
              value={this.state.inputValue}
              placeholder="Search Course, Country, Region..."
              onClick={this.handleClick}
              onChange={this.handleChange}
            />
          </div>

          {Optional(this.state.showDropdown).map(() =>
            <div key="where-dropdown" className="filter--where__dropdown">
              <Tabs>
                <Tab label="Locations">
                  <PlacesSearchBox
                    firstLocation={this.state.query.input}
                    clickHandler={this.handleFetch}
                    query={this.state.query}
                  />
                </Tab>
                <Tab label="Courses">
                  <CoursesIdentitiesDropdown
                    {...this.props.pop}
                    query={this.state.query.input}
                  />
                </Tab>
              </Tabs>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default onClickOutside(FilterWhere);
