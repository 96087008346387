import React from 'react';
import { isIosDevice, isAndroidDevice } from '../helpers/browser';

const StoreButtons = (props) => {
  const downloadIosEvent = () => ga('send', 'event', {
    eventCategory: 'download',
    eventAction: 'click',
    eventLabel: 'iOS',
  });

  const downloadAndroidEvent = () => ga('send', 'event', {
    eventCategory: 'download',
    eventAction: 'click',
    eventLabel: 'Android',
  });

  const appleStoreButton = () => {
    return <a key="apple-store-btn" onClick={downloadIosEvent} href="https://hole19.smart.link/7th5qzcmg" target="_blank" className="button-store apple"></a>;
  }

  const googlePlayButton = () => {
    return <a key="google-store-btn" onClick={downloadAndroidEvent} href="https://hole19.smart.link/zgokqd353" target="_blank" className="button-store google"></a>;
  }

  /**
   * Render buttons based on the device's operating system (iOS or Android).
   * If showOnlySupportedStore is set to true, it will render only the supported store.
   * Otherwise, it will render both buttons.
   */
  const renderButtons = () => {
    const showOnlySupportedStore = props.showOnlySupportedStore === true;

    if (showOnlySupportedStore && isIosDevice()) {
      return appleStoreButton();
    } else if (showOnlySupportedStore && isAndroidDevice()) {
      return googlePlayButton();
    } else {
      return ([appleStoreButton(), googlePlayButton()]);
    }
  }

  return (
    <div className="buttons-stores">
      {renderButtons()}
    </div>
  );
};

export default StoreButtons;
