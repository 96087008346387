import React from 'react';
import SubscriptionHelpers from '../../helpers/subscriptions';
import Loader from '../Loader.es6.jsx';

const withPreferences = ({
  url,
  api,
  userEmail,
  title,
  showAlertHandler,
  showSaveAllBoxHandler,
  authToken,
  toWrapped = {}, // additional params to pass to the wrapped component
}) => WrappedComponent =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        initialSubscriptions: [],
        subscriptions: [],
        alertCSSClass: 'success',
        isLoading: true,
      };
    }

    componentDidMount() {
      this.getSubscriptions();
      this.setGlobalHandlers();
    }

    setGlobalHandlers = () => {
      window.subscriptions = window.subscriptions || {};
      window.subscriptions.saveHandlers = window.subscriptions.saveHandlers || {};
      window.subscriptions.discardHandlers = window.subscriptions.discardHandlers || {};
      window.subscriptions.saveHandlers[title] = this.saveAllSubscriptions;
      window.subscriptions.discardHandlers[title] = this.discardChanges;
    };

    getSubscriptions() {
      const params = (authToken && SubscriptionHelpers.setParams({ email: userEmail, token: authToken })) || {};

      SubscriptionHelpers.getSubscriptions({ api, url }, params)
        .then(subscriptions => this.setState({ subscriptions, initialSubscriptions: subscriptions, isLoading: false }));
    }

    discardChanges = () =>
      new Promise(resolve => this.setState({ subscriptions: this.state.initialSubscriptions }, resolve));

    saveAllSubscriptions = () =>
      new Promise((resolve, reject) => {
        const subscriptions = JSON.parse(JSON.stringify(this.state.subscriptions));
        const params = SubscriptionHelpers.setParams({ email: userEmail, subscriptions, token: authToken });

        SubscriptionHelpers.updateSubscription({ api, url }, params)
          .then(() => this.setState({ initialSubscriptions: subscriptions }))
          .then(() => resolve(showAlertHandler && showAlertHandler('success')))
          .catch((e) => { this.setState({ subscriptions: this.state.initialSubscriptions }); reject(e); });
      });

    updateSubscriptionHandler = (key, value, callback = () => {}) => {
      const subscriptions = JSON.parse(JSON.stringify(this.state.subscriptions));
      const subscriptionIndex = subscriptions.findIndex(obj => obj.key === key);
      subscriptions[subscriptionIndex].value = value;

      this.setState({ subscriptions }, () => {
        if (callback) {
          callback();
        }
        if (showSaveAllBoxHandler) {
          showSaveAllBoxHandler();
        }
      });
    };

    render() {
      const params = {
        userEmail,
        initialSubscriptions: this.state.initialSubscriptions,
        subscriptions: this.state.subscriptions,
        changeHandler: this.updateSubscriptionHandler,
      };

      return this.state.isLoading ? (
        <Loader center="horizontal" />
      ) : (
        <WrappedComponent {...params} {...toWrapped} />
      );
    }
  };

export default withPreferences;
